import React, { useState } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage, sanitize } from "../../utils/functions";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form"
import { v4 } from "uuid";
import PASSWORD_RECOVERY from "../../mutations/passwordRecovery";
import Spin from "../../components/spin/Spin";
import "./style.scss";


const RecoveryPasswordPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, acfRecoveryPassword
    },
  } = props;

  const { textAfterForm, textBeforeForm, textInButton, textLabel, errorFieldRequired, successMessage } = acfRecoveryPassword;

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (values, e) => {
    if (loading) return;
    setLoading(true);
    setErrorMessage('');
    setUsername(values.username)
    recoveryPasswordAction();
  }

  const [
    recoveryPasswordAction,
    { loading: recoveryPasswordLoading, error: registerError },
  ] = useMutation(PASSWORD_RECOVERY, {
    variables: {
      input: {
        username: username
      },
    },

    onCompleted: (data) => {
      // If error.
      if (!isEmpty(registerError)) {
        setErrorMessage(registerError.graphQLErrors[0].message);
      }
      else {
        setSuccess(true);
      }
      setLoading(false);
    },

    onError: (error) => {
      if (error) {
        if (!isEmpty(error)) {
          setLoading(false);
          setErrorMessage(error.graphQLErrors[0].message);
        }
      }
    },

  });

  return (
    <Layout lang={lang} page="recoveryPasswordPage" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"recoveryPasswordPage"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="container container--small">
              <div className="recoveryPasswordPage page">
                <h2 className="recoveryPasswordPage__heading heading--big heading--dash">{title}</h2>
                {textBeforeForm &&
                  <div className="content recoveryPasswordPage__before">
                    <div dangerouslySetInnerHTML={{ __html: textBeforeForm }} />
                  </div>
                }
                <form className="form recoveryPasswordPage__wrapper" onSubmit={handleSubmit(onSubmit)}>

                  <div className="form__input">
                    <label className="form__label" htmlFor="username">
                      {textLabel}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="username"
                      type="text"
                      {...register("username", {
                        required: true,
                      })}
                    />
                    <span className="form__error">{errors.username && errorFieldRequired}</span>
                  </div>

                  {!isEmpty(errorMessage) && (
                    <div
                      className="alert alert-danger"
                      dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }}
                    />
                  )}

                  <div className="recoveryPasswordPage__button form__button">
                    <button type="submit">{textInButton}</button>
                    {loading ? <Spin /> : ""}
                  </div>

                  {success && <div className="form__thx">{successMessage}</div>}

                </form>

                {textAfterForm &&
                  <div className="content recoveryPasswordPage__after">
                    <div dangerouslySetInnerHTML={{ __html: textAfterForm }} />
                  </div>
                }
              </div>
            </div>

          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default RecoveryPasswordPage;

